<template>
    <div class="col-12 g">
        <div class="card card-body">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-lg-3 g">
                        <span>البحث عن طالب</span>
                        <input type="text" placeholder="ابحث هنا..." class="form-control" v-model="q" @change="getStudents()" @keyup="getStudents()">
                    </div>
                    <div class="col-12 col-lg-3 g">
                        <span>عرض صف معين</span>
                        <select class="form-control" v-model="classname" @change="cc(); getStudents()">
                            <option value="">الكل</option>
                            <option v-for="c in classes" :key="c._id" :value="c.title">
                                {{ c.title }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-lg-3 g">
                        <span>عرض فصل معين</span>
                        <select class="form-control" v-model="classroom" @change="getStudents()">
                            <option value="" v-if="classname == ''">-- اختر الصف اولاً --</option>
                            <option value="" v-if="classname != ''">الكل</option>
                            <option v-for="c in classrooms" :key="c" :value="c">
                                {{ c }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-lg-2 g">
                        <span>&nbsp;</span>
                        <b-dropdown style="width: 100%"
                            id="dropdown-1" dropleft
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            text="خيارات"
                            variant="primary"
                            size=""
                        >
                            <b-dropdown-item @click="checkFPNow(true)">
                                <span class="text-success">
                                <i class="fa fa-hand-o-up"></i> عرض من لديه بصمة</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="checkFPNow(false)">
                                <span class="text-danger">
                                <i class="fa fa-hand-o-up"></i> عرض من ليس لديه بصمة</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="checkWhatsappNow()">
                                <i class="fa fa-whatsapp"></i>
                                هل الارقام عليها واتساب؟
                            </b-dropdown-item>
                            <b-dropdown-item @click="print()">
                                <i class="fa fa-print"></i>
                                طباعة الكشف المعروض
                            </b-dropdown-item>
                            <b-dropdown-item @click="exportExcel()">
                                <i class="fa fa-file"></i>
                                تصدير الى اكسل Excel
                            </b-dropdown-item>
                            <b-dropdown-item @click="getDevices(); selected_students_devices = JSON.parse(JSON.stringify(students))" v-b-modal.devices>
                                <i class="fa fa-download"></i>
                                نقل المعروضين لجهاز البصمة
                            </b-dropdown-item>
                            <b-dropdown-item @click="barcodes(students, false)">
                                <i class="fa fa-barcode"></i>
                                طباعة بطاقات الباركود
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteStudents()">
                                <i class="fa fa-user-times"></i>
                                حذف الطلاب المعروضين
                            </b-dropdown-item>
                            <b-dropdown-item @click="removeCache()">
                                <i class="fa fa-refresh"></i>
                                تحديث البيانات
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
            <div class="col-12 table-responsive" style="min-height: 500px !important">
                <table class="table table-hover table-sm table-striped table-bordered" id="studentstable">
                    <thead>
                        <th>م</th>
                        <th>
                            <a href="javascript:;" @click="ordertable('name')">الاسم <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('number')">الهوية <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('phones')">الجوال <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('classname')">الصف <i class="fa fa-sort"></i></a>
                        </th>
                        <th>
                            <a href="javascript:;" @click="ordertable('classroom')">الفصل <i class="fa fa-sort"></i></a>
                        </th>
                        <th v-if="checkWhatsApp">
                            لديه واتساب
                        </th>
                        <th v-if="checkFP">
                            البصمة
                        </th>
                        <th>
                            مجموعة الاعدادات
                        </th>
                        <th class="hidemonprint">
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="(student,i) in students">
                        <tr :key="student._id">
                            <td>{{ ppage(i+1) }}</td>
                            <td>
                                {{ student.name }}
                            </td>
                            <td>
                                {{ student.number }}
                            </td>
                            <td>
                                {{ student.phones.join(",") }}
                                <span v-if="student.fcm && student.fcm != ''">
                                    <img :src="require('@/assets/images/mobile-check.png')" width="20" title="تم تسجيل الدخول من التطبيق">
                                </span>
                            </td>
                            <td>
                                {{ student.classname }}
                            </td>
                            <td>
                                {{ student.classroom }}
                            </td>
                            <td v-if="checkWhatsApp">
                                <span v-if="loading2" class="text-danger"><img :src="require('@/assets/images/loading.svg')" width="20"> جاري الفحص... <small class="text-muted">ربما تستغرق دقيقة.</small></span>
                                <span v-if="!loading2  && student.phones.length > 1">
                                   <ul> <li v-for="phone in student.phones" :key="phone" v-html="hasWhatsBulk(phone)"></li></ul>
                                </span>
                                <span v-if="!loading2 && student.phones.length == 1 || !loading2 && student.phones.length == 0"  v-html="hasWhats(student.phones[0])"> 
                                </span>
                            </td>
                            <td v-if="checkFP">
                                <div v-if="!loading2">
                                    <span v-if="fps.includes(student.number)" class="text-success"><i class="fa fa-check"></i> لديه بصمة</span>
                                    <span v-if="!fps.includes(student.number)" class="text-danger"><i class="fa fa-times"></i> ليس لديه</span>
                                </div>
                                <span v-if="loading2" class="text-danger"><img :src="require('@/assets/images/loading.svg')" width="20"> جاري الفحص...</span>
                            </td>
                            <td>
                                {{ settingsGroupName(student.group_id) }}
                            </td>
                            <td class="hidemonprint" v-if="!exporting">
                            <b-dropdown dropleft style="width: 100%"
                                id="dropdown-1"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                text="خيارات"
                                variant="secondary"
                                size="sm"
                            >
                                <b-dropdown-item @click="$router.push(`/_students/full-report/${student.number}`)">
                                    <i class="fa fa-pie-chart"></i>
                                    تقرير شامل للطالب
                                </b-dropdown-item>
                                <b-dropdown-item @click="fetchNotes(student.number)" v-b-modal.notes>
                                    <i class="fa fa-lightbulb-o"></i>
                                    ملاحظات على الطالب
                                </b-dropdown-item>
                                <b-dropdown-item @click="fetchHealths(student.number)" v-b-modal.healths>
                                    <i class="fa fa-heart"></i>
                                    ملاحظات صحية
                                </b-dropdown-item>
                                <b-dropdown-item v-if="checkPer('students|students|edit')" @click="$router.push(`/_students/edit/${student._id}`)">
                                    <i class="fa fa-edit"></i>
                                    تعديل الطالب
                                </b-dropdown-item>
                                <b-dropdown-item @click="barcodes([student])">
                                    <i class="fa fa-barcode"></i>
                                    طباعة ملصق الباركود
                                </b-dropdown-item>
                                <b-dropdown-item v-if="checkPer('students|students|delete')" @click="deleteStudent(student._id)">
                                    <i class="fa fa-trash"></i>
                                    حذف الطالب
                                </b-dropdown-item>
                            </b-dropdown>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <button class="dnone opennotes" v-b-modal.notes></button>
            <div class="col-12">
                <button class="btn btn-danger" @click="page--; getStudents()" v-if=" page > 1 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
                <button class="btn btn-primary" @click="page++; getStudents()" v-if="students.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>

                &nbsp;
                ( اجمالي الطلاب: {{ total }} )
            </div>
        </div>
        <b-modal id="notes" title="ملاحظات على الطالب" hide-footer size="lg">
            <div class="form-group">
                <label for="">الملاحظة</label>
                <textarea type="text"
                class="form-control" v-model="note" placeholder="اكتب هنا..."></textarea>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="notealert" :value="true">
                هل تريد اظهار هذا التنبيه اذا حضر الطالب؟
                </label>
            </div>
            <div class="col-12 text-center">
                <br>
                <button class="btn btn-primary" @click="addNote()">
                    <i class="fa fa-check"></i>
                    اضافة الملاحظة
                </button>
            </div>
            <hr>
            <div class="col-12 table-responsive">
                <table class="table table-sm table-hover table-bordered">
                    <thead>
                        <th>
                            الملاحظة
                        </th>
                        <th>
                            اظهار تنبيه
                        </th>
                        <th>
                            حذف
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="n in notes" :key="n._id">
                            <td>
                                {{ n.content }}
                            </td>
                            <td>
                                <button class="btn btn-sm btn-success" @click="toggleNoteAlert(n._id)" v-if="n.alert">
                                    <i class="fa fa-bell"></i>
                                    سوف يظهر تنبيه
                                </button>
                                <button class="btn btn-sm btn-secondary" @click="toggleNoteAlert(n._id)" v-if="!n.alert">
                                    <i class="fa fa-bell-slash"></i>
                                    لن يظهر تنبيه
                                </button> <small><i class="text-muted">اضغط للتبديل</i></small>
                            </td>
                            <td>
                                <button class="btn btn-danger btn-sm" @click="deleteNote(n._id)">
                                    <i class="fa fa-trash"></i> حذف
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="healths" title="ملاحظات صحية على الطالب" hide-footer size="lg">
            <div class="form-group">
                <label for="">الملاحظة</label>
                <textarea type="text"
                class="form-control" v-model="health" placeholder="اكتب هنا..."></textarea>
            </div>
            <div class="col-12 text-center">
                <br>
                <button class="btn btn-primary" @click="addHealth()">
                    <i class="fa fa-check"></i>
                    اضافة الملاحظة الصحية
                </button>
            </div>
            <hr>
            <div class="col-12 table-responsive">
                <table class="table table-sm table-hover table-bordered">
                    <thead>
                        <th>
                            الملاحظة
                        </th>
                        <th>
                            حذف
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="n in healths" :key="n._id">
                            <td>
                                {{ n.content }}
                            </td>
                            <td>
                                <button class="btn btn-danger btn-sm" @click="deleteHealth(n._id)">
                                    <i class="fa fa-trash"></i> حذف
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="devices" title="نقل الطلاب لجهاز البصمة" hide-footer size="lg">
            <div class="g">
            اختر الطلاب <a href="javascript:;" @click="selected_students_devices = students"><u>اختيار الكل</u></a>
            </div>
            <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
                <div class="form-check" v-for="student in students" :key="student._id">
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="selected_students_devices" :value="student">
                    {{ student.name }}
                </label>
                </div>
            </div>
            <br>
            <p>اختر الجهاز المراد النقل له</p>
            <table class="table table-sm table-hover table-bordered">
            <thead>
                <th>
                    الجهاز
                </th>
                <th>
                    نقل اليه
                </th>
            </thead>
            <tbody>
                <tr v-for="n in devices" :key="n._id">
                    <td>
                        {{ n.title }}
                    </td>
                    <td>
                        <button class="btn btn-success btn-sm" @click="movetodevices(n.serial_number)" v-if="!objloading[n._id]">
                            <i class="fa fa-download"></i> نقل الى الجهاز
                        </button>
                        <span v-if="objloading[n._id]">جاري النقل...</span>
                    </td>
                </tr>
            </tbody>
        </table>
        </b-modal>
    </div>
</template>

<script>
var XLSX = require("xlsx");
import { BModal, VBModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            exporting: false,
            loading: true,
            loading2: true,
            checkWhatsApp: false,
            user: JSON.parse(localStorage.getItem('user')),
            students: [],
            groups: [],
            classes: [],
            classrooms: [],
            checkFP: false,
            classname: "",
            classroom: "",
            q: "",
            page: 1,
            perpage: 100,
            groups_obj: {},
            whatsapps: {},
            notes: [],
            note: "",
            xa: -1,
            xb: 1,
            last_sort: "f",
            notealert: false,
            currentNumber: "",
            healths: [],
            health: "",
            devices: [],
            objloading: {},
            selected_students_devices: [],
            checkFP: false,
            fps: [],
            checkfpstatus: true,
            total: 0
        }
    },
    created(){
        if(!checkPer("students|students|view")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/groups', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.groups = r.response
                g.groups.forEach(function(a){
                    g.groups_obj[a._id] = a
                })
            }
        }).fail(function(){
        })
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.classes = r.response
            }
        }).fail(function(){
        })
        if(window.location.hash){
            g.fetchNotes(window.location.hash.split('-')[1]);
            setTimeout(function(){
                $(".opennotes").click()
            }, 500)
        }
        g.getStudents()
    },
    methods: {
        ordertable(m){
            var x = this.students, g = this;
            if(g.last_sort == m){
                g.xa = g.xa == 1 ? -1 : 1;
                g.xb = g.xb == -1 ? 1 : -1;
            }
            g.last_sort = m;
            function compare( a, b ) {
                if ( a[m] < b[m] ){
                    return g.xa;
                }
                if ( a[m] > b[m] ){
                    return g.xb;
                }
                return 0;
            }
            x.sort(compare);
            this.students = []
            var g = this;
            setTimeout(() => {
                g.students = JSON.parse(JSON.stringify(x))
            }, 100);
        },
        ppage(i){
            if(this.page == 1){
                return i;
            }else{
                return i + ((this.page-1) * 100)
            }
        },
        removeCache(){
            var g = this;
            $.post(api + '/user/students/remove-cache', {
                jwt: this.user.jwt,
            }).then(function(){
                g.getStudents()
            })
        },
        exportExcel(){
            const wb = XLSX.utils.book_new(), g = this;
            const Heading = [
                [
                    "م",
                    "الاسم",
                    "الهوية",
                    "الجوال",
                    "الصف",
                    "الفصل",
                    "مجموعة الاعدادات"
                ]
            ];
            var xx = [];
            g.students.forEach(function(a, i){
                xx.push([
                    i+1,
                    a.name,
                    a.number,
                    a?.phones[0] ?? "",
                    a.classname,
                    a.classroom,
                    g.settingsGroupName(a.group_id)
                ])
            })
            
            // creating sheet and adding data from 2nd row of column A.
            // leaving first row to add Heading
            const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
                
            // adding heading to the first row of the created sheet.
            // sheet already have contents from above statement.
            XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
                
            // appending sheet with a name
            XLSX.utils.book_append_sheet(wb, ws, 'Records');
                
            const fileContent = XLSX.writeFile(wb, $("title").first().text().split("—") + ".xlsx");
        },
        getStudents(rr= false, rs = false){
            var g = this;
            g.loading = true;
            $.post(api + '/user/students/list', {
                jwt: this.user.jwt,
                q: this.q,
                classname: this.classname,
                classroom: this.classroom,
                page: this.page,
                perpage: this.perpage
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.total = r.total;
                    g.students = r.response
                    if(rr){
                        g.students = r.response.filter(function(x){
                            if(rr.includes(x.number) && rs == true || !rr.includes(x.number) && rs == false){
                                return x;
                            }else{
                                return false;
                            }
                        })
                        g.fps = JSON.parse(JSON.stringify(rr))
                    }
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        cc(){
            var g = this
            g.classes.forEach(function(a){
                if(a.title == g.classname){
                    g.classrooms = a.classrooms
                }
            })
        },
        movetodevices(device_id){
            var g = this;
            g.objloading[device_id] = true;
            g.objloading = JSON.parse(JSON.stringify(g.objloading))
            $.post(api + '/user/devices/move-users-to-devices', {
                jwt: this.user.jwt,
                devices: [device_id],
                users: g.selected_students_devices.map(function(a){
                    return {
                        name: a.name,
                        number: a.number
                    }
                })
            }).then(function(r){
                g.objloading[device_id] = false;
                g.objloading = JSON.parse(JSON.stringify(g.objloading))
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم بنجاح", 100)
                }
            }).fail(function(){
                g.objloading[device_id] = false;
                g.objloading = JSON.parse(JSON.stringify(g.objloading))
            })
        },
        settingsGroupName(id){
            var g = this;
            if(g.groups_obj[id]){
                return g.groups_obj[id].title
            }
            return "--"
        },
        checkWhatsappNow(){
            var g = this;
            g.checkWhatsApp = true;
            g.loading2 = true;
            $.post(api + '/user/students/check-whatsapps', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading2 = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.whatsapps = r.response
                }
            }).fail(function(){
                g.loading2 = false;
            })
        },
        checkFPNow(status){
            var g = this;
            g.checkfpstatus = status;
            g.checkFP = true;
            g.loading2 = true;
            $.post(api + '/user/students/check-fp', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading2 = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.perpage = 2000;
                    g.getStudents(r.response, status)
                }
            }).fail(function(){
                g.loading2 = false;
            })
        },
        hasWhatsBulk(phone){
            if(this.whatsapps[phone]){
                if(this.whatsapps[phone] == '1'){
                    return `<span class='text-success btn-block'><i class='fa fa-check'></i> لديه واتساب (${phone})</span>`
                }else if(this.whatsapps[phone] == '2'){
                    return `<span class='text-danger btn-block'><i class='fa fa-times'></i> (${phone})</span>`
                }else{
                    return `<span class='text-warning btn-block'><i class='fa fa-info'></i> الرقم خطأ (${phone})</span>`
                }
            }
        },
        hasWhats(phone){
            if(this.whatsapps[phone]){
                if(this.whatsapps[phone] == '1'){
                    return `<span class='text-success btn-sm text-whitfe btn-block'><i class='fa fa-check'></i> لديه واتساب</span>`
                }else if(this.whatsapps[phone] == '2'){
                    return `<span class='text-danger btn-sm text-whitef btn-block'><i class='fa fa-times'></i> ليس لديه</span>`
                }else{
                    return `<span class='text-warning btn-sm text-darkf btn-block'><i class='fa fa-info'></i> الرقم خطأ</span>`
                }
            }
        },
        getDevices(){
            var g = this;
            if(g.devices.length){
                return;
            }
            $.post(api + '/user/devices/all', {
                jwt: this.user.jwt
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.devices = r.response
                }
            }).fail(function(){
            })
        },
        deleteStudent(id){
            var g = this;
            if(confirm("متاكد من حذف الطالب؟")){
                $.post(api + '/user/students/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading2 = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getStudents()
                    }
                }).fail(function(){
                    g.loading2 = false;
                })
            }
        },
        print(){
            var divToPrint=document.getElementById("studentstable");
            var newWin= window.open("");
            newWin.document.write(`
            <style>
            html, body{
                direction: rtl
            }
            .hidemonprint{
                display:none;   
            }
            table, td, th {
            border: 1px solid;
            }

            table {
            width: 100%;
            border-collapse: collapse;
            }
            </style>
            ` + divToPrint.outerHTML);
            newWin.document.close()
            newWin.print();
        },
        fetchNotes(number){
            var g = this;
            g.currentNumber = number;
            $.post(api + '/user/students/notes', {
                jwt: this.user.jwt,
                number: number
            }).then(function(r){
                g.notes = []
                if(r.status == 100){
                    g.notes = r.response
                }
            }).fail(function(){
                alert("حدث خطأ")
            })
        },
        fetchHealths(number){
            var g = this;
            g.currentNumber = number;
            $.post(api + '/user/students/health', {
                jwt: this.user.jwt,
                number: number
            }).then(function(r){
                g.healths = []
                if(r.status == 100){
                    g.healths = r.response
                }
            }).fail(function(){
                alert("حدث خطأ")
            })
        },
        addNote(){
            var g = this;
            $.post(api + '/user/students/notes/add', {
                jwt: this.user.jwt,
                number: g.currentNumber,
                alert: g.notealert,
                content: g.note
            }).then(function(r){
                g.fetchNotes(g.currentNumber)
                g.note = "";
                g.notealert = false;
            }).fail(function(){
                alert("حدث خطأ")
            })
        },
        addHealth(){
            var g = this;
            $.post(api + '/user/students/health/add', {
                jwt: this.user.jwt,
                number: g.currentNumber,
                content: g.health
            }).then(function(r){
                g.fetchHealths(g.currentNumber)
                g.health = "";
            }).fail(function(){
                alert("حدث خطأ")
            })
        },
        toggleNoteAlert(id){
            var g = this;
            $.post(api + '/user/students/notes/toggle', {
                jwt: this.user.jwt,
                id: id
            }).then(function(r){
                g.fetchNotes(g.currentNumber)
            }).fail(function(){
                alert("حدث خطأ")
            })
        },
        deleteNote(id){
            var g = this;
            $.post(api + '/user/students/notes/delete', {
                jwt: this.user.jwt,
                id: id
            }).then(function(r){
                g.fetchNotes(g.currentNumber)
            }).fail(function(){
                alert("حدث خطأ")
            })
        },
        deleteHealth(id){
            var g = this;
            $.post(api + '/user/students/health/delete', {
                jwt: this.user.jwt,
                id: id
            }).then(function(r){
                g.fetchHealths(g.currentNumber)
            }).fail(function(){
                alert("حدث خطأ")
            })
        },
        barcodes(students, s=false){
            var g = this;
            var newWin= window.open("");
            var arr = "";
            students.forEach(function(student){
            arr = arr + `
            <div class="card" style="height: 14.2857143vh; width: 33.33333%; padding: 10px;">
            <div class="card-body text-center" style="border: 1px solid #999; border-width: 4px;">
                <p>${student.name}
                </p>
                <img src="https://barcodeapi.org/api/128/${encodeURI(student.number)}" alt="barcode" style="width: 80%; height: 50px; "   />
            </div>
        </div>
            `
        })
            newWin.document.write(`
            <html>
                <head>
                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css">
                    <meta charset="utf-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                </head>
                <body>
                    <div class="container-fluid">
                        <div class="row">
                        ${arr}
                        </div>
                    </div>
                </body>
            </html>
            `);
            newWin.document.close()
            setTimeout(() => {
                newWin.print();
            }, 3000);
        },
        deleteStudents(){
            var g = this;
            if(confirm(`متأكد من حذف ${g.students.length} طالب نهائياً؟`)){
                $.post(api + '/user/students/delete-bulk', {
                    jwt: this.user.jwt,
                    ids: this.students.map(function(x){
                        return x._id
                    })
                }).then(function(r){
                    alert("تم الحذف بنجاح", 100)
                    g.getStudents()
                }).fail(function(){
                    alert("حدث خطأ")
                })
            }
        }
    }
}
</script>
<style scoped>
.table,th,td,tr{
    white-space: nowrap !important;
}
</style>